<template>
    <div>
        <ContextTitle :passedActions="titleBarActions" title="Tabletop Logos" />
        <v-container fluid>
            <v-card width="100%" class="pa-3">
                <v-row class="mt-3">
                    <v-col md="8" class="pt-0">
                        <store-search-new
                            :airport="airport"
                            :waypoint="waypointId"
                            @airport-change="airport = $event"
                            @waypoint-change="onLocationChange"
                        />
                    </v-col>
                </v-row>
            </v-card>
            <v-row no-gutters>
                <v-col class="fill-height">
                    <v-row justify="center" class="overflow-y-auto mt-5">
                        <v-col>
                            <h5 v-if="!isWaypointsSelected" class="pt-3">
                                Select a waypoint to start
                            </h5>
                        </v-col>
                    </v-row>
                    <v-row
                        no-gutters
                        class="mt-0"
                        v-if="
                            waypointId != undefined &&
                            this.stageAssets != undefined
                        "
                    >
                        <v-col md="6" sm="8" class="fill-height pr-1">
                            <v-card>
                                <v-card-title>Stage</v-card-title>
                                <v-card-text>
                                    <v-col md="12" class="fill-height">
                                        <v-row
                                            no-gutters
                                            v-for="field in fields"
                                            :key="field.key"
                                        >
                                            <v-col cols="12">
                                                <v-row no-gutters>
                                                    <v-col
                                                        cols="5"
                                                        class="text-h6 black--text"
                                                        >{{
                                                            field.title
                                                        }}</v-col
                                                    >
                                                    <v-col cols="5">
                                                        <text-tooltip
                                                            v-if="field.doc"
                                                            :label="field.doc"
                                                        />
                                                    </v-col>
                                                </v-row>
                                                <v-row no-gutters>
                                                    <v-img
                                                        :src="
                                                            urlForField(
                                                                field.key
                                                            )
                                                        "
                                                        width="300"
                                                        height="300"
                                                        max-width="300"
                                                        max-height="300"
                                                        contain
                                                    >
                                                        <template
                                                            v-slot:placeholder
                                                        >
                                                            <v-row
                                                                class="fill-height ma-0"
                                                                align="center"
                                                                justify="center"
                                                            >
                                                                <v-progress-circular
                                                                    indeterminate
                                                                    color="#93BD20"
                                                                ></v-progress-circular>
                                                            </v-row>
                                                        </template> </v-img
                                                ></v-row>
                                                <v-row no-gutters>
                                                    <v-file-input
                                                        show-size
                                                        :label="`Select file for ${field.title}`"
                                                        accept=".svg"
                                                        v-model="
                                                            files[field.key]
                                                        "
                                                    ></v-file-input>
                                                </v-row>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col md="6" class="fill-height pl-1">
                            <v-card>
                                <v-card-title>Prod</v-card-title>
                                <v-card-text>
                                    <v-col md="12" class="fill-height">
                                        <v-row
                                            no-gutters
                                            v-for="field in fields"
                                            :key="field.key"
                                        >
                                            <v-col cols="12">
                                                <v-row no-gutters>
                                                    <v-col
                                                        cols="5"
                                                        class="text-h6 black--text"
                                                        >{{
                                                            field.title
                                                        }}</v-col
                                                    >
                                                    <v-col cols="5">
                                                        <text-tooltip
                                                            v-if="field.doc"
                                                            :label="field.doc"
                                                        />
                                                    </v-col>
                                                </v-row>
                                                <v-row no-gutters>
                                                    <v-img
                                                        :src="
                                                            prodUrlForField(
                                                                field.key, 'prod'
                                                            )
                                                        "
                                                        width="300"
                                                        height="300"
                                                        max-width="300"
                                                        max-height="300"
                                                        contain
                                                    >
                                                        <template
                                                            v-slot:placeholder
                                                        >
                                                            <v-row
                                                                class="fill-height ma-0"
                                                                align="center"
                                                                justify="center"
                                                            >
                                                                <v-progress-circular
                                                                    indeterminate
                                                                    color="#93BD20"
                                                                ></v-progress-circular>
                                                            </v-row>
                                                        </template> </v-img
                                                ></v-row>
                                                <v-row no-gutters>
                                                    <v-file-input
                                                        show-size
                                                        :label="`Select file for ${field.title}`"
                                                        accept=".svg"
                                                        disabled
                                                        v-model="
                                                            files[field.key]
                                                        "
                                                    ></v-file-input>
                                                </v-row>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import _ from "lodash";
import ContextTitle from "components/context_title.vue";
import StoreSearchNew from "components/store_search_new";
import { mapActions, mapGetters } from "vuex";
import TextTooltip from "components/text_tooltip";

import clone from "clone";

export default {
    name: "SVGIndex",
    data() {
        return {
            titleBarActions: [
                {
                    type: "proceed",
                    display: "Save",
                    run: () => {
                        this.save();
                    },
                },
                {
                    type: "duplicate",
                    display: "Publish To Prod",
                    run: () => {
                        this.publish();
                    },
                },
                {
                    type: "",
                    display: "Refresh",
                    run: () => {
                        this.refresh();
                    },
                },
            ],
            selectedPlatformTypes: [],
            airport: null,
            waypointId: null,
            isMetadataLoading: false,
            isConfigLoading: false,
            isSaveInProgress: false,
            stageAssets: undefined,
            files: {},
        };
    },
    components: {
        ContextTitle,
        StoreSearchNew,
        TextTooltip,
    },
    computed: {
        fields() {
            if (
                this.svgAssetsMetadata == undefined ||
                Object.keys(this.svgAssetsMetadata).length <= 1
            )
                return [];
            return this.svgAssetsMetadata.fields;
        },
        isWaypointsSelected() {
            return this.waypointId !== undefined;
        },
        ...mapGetters("ConfigStore", [
            "svgAssets",
            "configStoreWaypointID",
            "svgAssetsMetadata",
        ]),
    },
    async mounted() {
        await Promise.all([this.debounced_getMetadata()]);
        this.waypointId = this.configStoreWaypointID;
        this.stageAssets = clone(this.svgAssets.stage);

        if (this.waypointId != undefined && this.stageAssets == undefined)
            this.onLocationChange(this.waypointId);
    },
    created() {
        this.debounced_getMetadata = _.debounce(this.getMetadata);
    },
    methods: {
        prodUrlForField(field) {
            if (this.svgAssets.prod[field] != undefined)
                return `data:image/svg+xml;utf8,${this.encodeSvg(
                    this.svgAssets.prod[field]
                )}`;
            return null;
        },
        urlForField(field) {
            if (this.files[field] !== undefined) {
                return URL.createObjectURL(this.files[field]);
            }
            if (this.stageAssets[field] != undefined)
                return `data:image/svg+xml;utf8,${this.encodeSvg(
                    this.stageAssets[field]
                )}`;
            return null;
        },
        encodeSvg(svgString) {
            return (
                svgString
                    .replace(
                        "<svg",
                        ~svgString.indexOf("xmlns")
                            ? "<svg"
                            : '<svg xmlns="http://www.w3.org/2000/svg"'
                    )

                    //
                    //   Encode (may need a few extra replacements)
                    //
                    .replace(/"/g, "'")
                    .replace(/%/g, "%25")
                    .replace(/#/g, "%23")
                    .replace(/{/g, "%7B")
                    .replace(/}/g, "%7D")
                    .replace(/</g, "%3C")
                    .replace(/>/g, "%3E")

                    .replace(/\s+/g, " ")
            //
            //    The maybe list (add on documented fail)
            //
            //  .replace(/&/g, '%26')
            //  .replace('|', '%7C')
            //  .replace('[', '%5B')
            //  .replace(']', '%5D')
            //  .replace('^', '%5E')
            //  .replace('`', '%60')
            //  .replace(';', '%3B')
            //  .replace('?', '%3F')
            //  .replace(':', '%3A')
            //  .replace('@', '%40')
            //  .replace('=', '%3D')
            );
        },
        onInput(field) {
            return (o) => {
                console.log(field);
                console.log(o);
                this.blobs[field] = o;
            };
        },
        getMetadata() {
            this.isMetadataLoading = true;
            Promise.all([this.getAssetsMetadata(this.waypointId)]).finally(
                () => {
                    this.isMetadataLoading = false;
                }
            );
        },
        onLocationChange(waypointId) {
            this.config = undefined;
            this.waypointId = waypointId;
            this.isConfigLoading = true;
            Promise.all([this.getSVGAssets(this.waypointId)]).finally(() => {
                this.isConfigLoading = false;
            });
        },
        onSearchChange(str) {
            this.searchString = str;
        },

        async save() {
            this.isSaveInProgress = true;

            for (const key in this.files) {
                if (Object.hasOwnProperty.call(this.files, key)) {
                    const element = this.files[key];
                    const string = await element.text();
                    this.stageAssets[key] = string;
                }
            }
            console.log(this.stageAssets);
            Promise.all([
                this.saveSVGAssets({
                    storeWaypointID: this.waypointId,
                    assets: this.stageAssets,
                }),
            ]).finally(() => {
                this.isSaveInProgress = false;
                this.files = {};
            });
        },
        publish() {
            this.isSaveInProgress = true;
            Promise.all([this.publishSVGAssets(this.waypointId)]).finally(
                () => {
                    this.isSaveInProgress = false;
                }
            );
        },
        refresh() {
            this.onLocationChange(this.waypointId);
        },
        ...mapActions("ConfigStore", [
            "getSVGAssets",
            "getAssetsMetadata",
            "saveSVGAssets",
            "publishSVGAssets",
        ]),
    },
    watch: {
        waypointId(value) {},
        svgAssets() {
            this.stageAssets = clone(this.svgAssets.stage);
        },
    },
};
</script>

<style lang="css" scoped>
.my-border {
    border: 2px solid black;
}
</style>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("ContextTitle", {
        attrs: { passedActions: _vm.titleBarActions, title: "Tabletop Logos" },
      }),
      _c(
        "v-container",
        { attrs: { fluid: "" } },
        [
          _c(
            "v-card",
            { staticClass: "pa-3", attrs: { width: "100%" } },
            [
              _c(
                "v-row",
                { staticClass: "mt-3" },
                [
                  _c(
                    "v-col",
                    { staticClass: "pt-0", attrs: { md: "8" } },
                    [
                      _c("store-search-new", {
                        attrs: {
                          airport: _vm.airport,
                          waypoint: _vm.waypointId,
                        },
                        on: {
                          "airport-change": function ($event) {
                            _vm.airport = $event
                          },
                          "waypoint-change": _vm.onLocationChange,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            { attrs: { "no-gutters": "" } },
            [
              _c(
                "v-col",
                { staticClass: "fill-height" },
                [
                  _c(
                    "v-row",
                    {
                      staticClass: "overflow-y-auto mt-5",
                      attrs: { justify: "center" },
                    },
                    [
                      _c("v-col", [
                        !_vm.isWaypointsSelected
                          ? _c("h5", { staticClass: "pt-3" }, [
                              _vm._v(" Select a waypoint to start "),
                            ])
                          : _vm._e(),
                      ]),
                    ],
                    1
                  ),
                  _vm.waypointId != undefined && this.stageAssets != undefined
                    ? _c(
                        "v-row",
                        { staticClass: "mt-0", attrs: { "no-gutters": "" } },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "fill-height pr-1",
                              attrs: { md: "6", sm: "8" },
                            },
                            [
                              _c(
                                "v-card",
                                [
                                  _c("v-card-title", [_vm._v("Stage")]),
                                  _c(
                                    "v-card-text",
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "fill-height",
                                          attrs: { md: "12" },
                                        },
                                        _vm._l(_vm.fields, function (field) {
                                          return _c(
                                            "v-row",
                                            {
                                              key: field.key,
                                              attrs: { "no-gutters": "" },
                                            },
                                            [
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "12" } },
                                                [
                                                  _c(
                                                    "v-row",
                                                    {
                                                      attrs: {
                                                        "no-gutters": "",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "v-col",
                                                        {
                                                          staticClass:
                                                            "text-h6 black--text",
                                                          attrs: { cols: "5" },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(field.title)
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "v-col",
                                                        {
                                                          attrs: { cols: "5" },
                                                        },
                                                        [
                                                          field.doc
                                                            ? _c(
                                                                "text-tooltip",
                                                                {
                                                                  attrs: {
                                                                    label:
                                                                      field.doc,
                                                                  },
                                                                }
                                                              )
                                                            : _vm._e(),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-row",
                                                    {
                                                      attrs: {
                                                        "no-gutters": "",
                                                      },
                                                    },
                                                    [
                                                      _c("v-img", {
                                                        attrs: {
                                                          src: _vm.urlForField(
                                                            field.key
                                                          ),
                                                          width: "300",
                                                          height: "300",
                                                          "max-width": "300",
                                                          "max-height": "300",
                                                          contain: "",
                                                        },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "placeholder",
                                                              fn: function () {
                                                                return [
                                                                  _c(
                                                                    "v-row",
                                                                    {
                                                                      staticClass:
                                                                        "fill-height ma-0",
                                                                      attrs: {
                                                                        align:
                                                                          "center",
                                                                        justify:
                                                                          "center",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-progress-circular",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              indeterminate:
                                                                                "",
                                                                              color:
                                                                                "#93BD20",
                                                                            },
                                                                        }
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ]
                                                              },
                                                              proxy: true,
                                                            },
                                                          ],
                                                          null,
                                                          true
                                                        ),
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-row",
                                                    {
                                                      attrs: {
                                                        "no-gutters": "",
                                                      },
                                                    },
                                                    [
                                                      _c("v-file-input", {
                                                        attrs: {
                                                          "show-size": "",
                                                          label: `Select file for ${field.title}`,
                                                          accept: ".svg",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.files[
                                                              field.key
                                                            ],
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.files,
                                                              field.key,
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "\n                                                        files[field.key]\n                                                    ",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        }),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "fill-height pl-1",
                              attrs: { md: "6" },
                            },
                            [
                              _c(
                                "v-card",
                                [
                                  _c("v-card-title", [_vm._v("Prod")]),
                                  _c(
                                    "v-card-text",
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "fill-height",
                                          attrs: { md: "12" },
                                        },
                                        _vm._l(_vm.fields, function (field) {
                                          return _c(
                                            "v-row",
                                            {
                                              key: field.key,
                                              attrs: { "no-gutters": "" },
                                            },
                                            [
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "12" } },
                                                [
                                                  _c(
                                                    "v-row",
                                                    {
                                                      attrs: {
                                                        "no-gutters": "",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "v-col",
                                                        {
                                                          staticClass:
                                                            "text-h6 black--text",
                                                          attrs: { cols: "5" },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(field.title)
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "v-col",
                                                        {
                                                          attrs: { cols: "5" },
                                                        },
                                                        [
                                                          field.doc
                                                            ? _c(
                                                                "text-tooltip",
                                                                {
                                                                  attrs: {
                                                                    label:
                                                                      field.doc,
                                                                  },
                                                                }
                                                              )
                                                            : _vm._e(),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-row",
                                                    {
                                                      attrs: {
                                                        "no-gutters": "",
                                                      },
                                                    },
                                                    [
                                                      _c("v-img", {
                                                        attrs: {
                                                          src: _vm.prodUrlForField(
                                                            field.key,
                                                            "prod"
                                                          ),
                                                          width: "300",
                                                          height: "300",
                                                          "max-width": "300",
                                                          "max-height": "300",
                                                          contain: "",
                                                        },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "placeholder",
                                                              fn: function () {
                                                                return [
                                                                  _c(
                                                                    "v-row",
                                                                    {
                                                                      staticClass:
                                                                        "fill-height ma-0",
                                                                      attrs: {
                                                                        align:
                                                                          "center",
                                                                        justify:
                                                                          "center",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-progress-circular",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              indeterminate:
                                                                                "",
                                                                              color:
                                                                                "#93BD20",
                                                                            },
                                                                        }
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ]
                                                              },
                                                              proxy: true,
                                                            },
                                                          ],
                                                          null,
                                                          true
                                                        ),
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-row",
                                                    {
                                                      attrs: {
                                                        "no-gutters": "",
                                                      },
                                                    },
                                                    [
                                                      _c("v-file-input", {
                                                        attrs: {
                                                          "show-size": "",
                                                          label: `Select file for ${field.title}`,
                                                          accept: ".svg",
                                                          disabled: "",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.files[
                                                              field.key
                                                            ],
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.files,
                                                              field.key,
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "\n                                                        files[field.key]\n                                                    ",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        }),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }